body {
  background-color: #bebebe;
}
.red {
  color: red;
}
.c {
  text-align: center;
}

hr {
  height: 2px; /* Control the thickness of the line */
  border: none; /* Remove the default border */
  background-color: black; /* Set the color of the line to black (or any darker color) */
  align: center;
  width: 50%;
}

h1 {
  margin: 0;
}

.card {
  background-color: #add8e5;
  margin: auto;
  margin-top: 10px;
  width: 450px;
  padding: 10px;
  color: #1b3e59;
  border-radius: 10px;
  box-shadow: 8px 8px 16px 2px #1b3e59;
}

a:hover {
  box-shadow: inset 450px 0 0 0 blue;
  color: white;
}

/* https://www.cssmatic.com/box-shadow */
